import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useStore } from "../../store/StoreProvider";
import M from "materialize-css";
import logo from "../../img/xpertlogo.png";
import user from "../../img/user.png";
import LinkRef from "../Others/LinkRef";
import Functions from "../../hooks/functions";
import SideNav from "./SideNav";
import wrapper from "../../hooks/wrapper";
import Breadcrums from "../Others/Breadcrums";
import {appid} from "../../hooks/url";
// import BreadcrumsClass from "../../js/BreadcrumsClass";
export default function Navbar(params) {
    const store = useStore()
    const Dispatch = useDispatch()
    const [DataNav, setDataNav] = useState({})
    const [TypeSesion, setTypeSesion] = useState('')
    // const [Bread, setBread] = useState([])
    const getData = useCallback(async () => {
        // console.log(store);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let id = urlParams.get('id') ?? '';

        const users = await wrapper.getDataU('users/'+id)
        //const users = await wrapper.getDataU('users')
        // console.log("user nav");
        const cart = await wrapper.getDataU('cart')
        Dispatch({ type: 'typeUser', data: users?.data?.data })
        // console.log("cart");
        // console.log(cart);
        let full = users?.data?.data?.user_full_name
        // console.log(users?.data?.data);
        // full = "Hector Eduardo"
        setTypeSesion(full)
        if (full !== "Invitado") {
            // console.log("NO es invitado");
            const cartCount = await wrapper.getDataU('cart/' + cart?.data?.data?.id)
            const menuUser = await wrapper.getDataU('users/menu/' + users?.data?.data?.user_id)
            // console.log(cartCount?.data?.data?.cart);
            if (cartCount?.data?.data?.cart?.total_items !== undefined) {
                Dispatch({ type: 'addcart', data: cartCount?.data?.data?.cart?.total_items })
            } else {
                Dispatch({ type: 'addcart', data: "0" })
            }
            let ordenesM = { Module: "Mis pedidos", ModuleClass: "/MyOrders", Icon: "assignment", link: true }
            let sus = { Module: "Mis suscripciones", ModuleClass: "/subscriptions", Icon: "art_track", link: true }
            let factura = { Module: "Facturación", ModuleClass: "/author/invoices", Icon: "credit_card", link: true }
            let dashboard = { Module: "Plataforma", ModuleClass: "https://academy.xpertme.com/dashboard", Icon: "dashboard", link: true }

            if (menuUser !== undefined) {
                menuUser.data.data.top_menu.splice(2, 0, ordenesM)
                menuUser.data.data.top_menu.splice(3, 0, sus)
                if (users?.data?.data?.author_id > 0) {
                    menuUser.data.data.top_menu.splice(4, 0, factura)
                }
                menuUser.data.data.top_menu.splice(5, 0, dashboard)

                menuUser.data.data.user_full_name = full
                setDataNav(menuUser.data.data)
            }
            // console.log(menuUser.data.data);
        } else {
            // console.log("invitado");
            const cartCount = await wrapper.getDataU('cart/' + users?.data?.data?.guest_id)
            // console.log("invitado");
            // console.log(cartCount);
            if (cartCount?.data?.data?.cart?.total_items !== undefined) {
                Dispatch({ type: 'addcart', data: cartCount?.data?.data?.cart?.total_items })
            } else {
                Dispatch({ type: 'addcart', data: "0" })
            }
            // Dispatch({ type: 'addcart', data: cartCount?.data?.data?.cart?.total_items })
        }
    }, [Dispatch])
    useEffect(() => {
        // console.log(window.location.host);
        var elem = document.querySelector('.sidenav');
        new M.Sidenav(elem, { edge: 'right' });
        getData()
    }, [getData])
    const listNav = (clas, id) => {
        // prueba()
        return (
            <div>
                <ul className={clas !== undefined ? clas : "right hide-on-med-and-down"} id={id !== undefined ? id : ''}>
                    <li className="sm"><a href="https://xpertme.com/" className=" candara ">Inicio</a></li>
                    <li className="sm"><a href="https://xpertme.com/Crea.html" className=" candara hide">Crea</a></li>
                    <li className="sm"><a href="https://xpertme.com/Industria4.0.html" className=" candara hide">Industria 4.0</a></li>
                    <li className="sm"><a href="https://xpertme.com/Servicios.html" className=" candara hide">Servicios</a></li>
                    <li className="sm"><a href="https://store.xpertme.com" className="candara">Tienda</a></li>
                    <li className="sm"><a href="https://xpertme.com/eon.html" className=" candara hide">eon</a></li>
                    <li className="sm"><a href="/#" className="candara hide">Eventos</a></li>
                    <li className="sm"><a href="https://xpertme.com/Contacto.html" className="candara hide">Contacto</a></li>
                    <li className="sm hide-on-med-and-down"><a href="/#" className="sidenav-overlay noHover grey-text text-darken-1 active fz-30">|</a></li>
                    {/*
                    <li className="sm hide-on-med-and-down">
                        <LinkRef clas="cart ml-15 mr-15" to="/cart">
                            <i className="material-icons black-text">shopping_cart</i>
                            <div className="left-5 count center-align">{store.cart}</div>
                        </LinkRef>
                    </li>
                    */}
                    {TypeSesion === "Invitado" ?
                        <li className="sm hide-on-med-and-down"><a href={"https://accounts.xpertme.com/?"+appid} className="text-primary">Iniciar sesión</a></li>
                        : ''}
                    {TypeSesion !== "Invitado" ?
                        <li className="sm hide-on-med-and-down">
                            <div className="avatar">
                                <div className="cuadro centrar pointer">
                                    <div className="ml-20 cirNav" onClick={() => Functions.dropdown('dropdown1')}>
                                        <img className="w-95 m-auto centrar h-100" src={user} alt="Logo" />

                                        {/*<div className="tooltip-left grey darken-4 white-text">
                                            {DataNav?.user_full_name}
                                        </div> */}
                                    </div>
                                    <ul id="dropdown1" className="dropdown-content dropdown-nav top-60 left--120">
                                        {DataNav?.top_menu?.map((e, i) => {
                                            return (
                                                <li className="centrar hpx-50" key={"top-nap" + i}>
                                                    <a className="sp w-100 h-100" href={e?.ModuleClass}>
                                                        <i className="material-icons">{e?.Icon}</i>
                                                        {e?.Module}
                                                    </a>
                                                </li>
                                            )
                                        })}

                                        <li className="centrar hpx-50" key="subscriptions">
                                            <a className="sp w-100 h-100" href="subscriptions">
                                                Mis Suscripciones
                                            </a>
                                        </li>
                                        <li className="centrar hpx-50" key="orders">
                                            <a className="sp w-100 h-100" href="MyOrders">
                                                Mis Órdenes
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        : ''}
                    {TypeSesion !== "Invitado" ?
                        <li className="sm">
                            <SideNav data={DataNav}/>
                        </li>
                        : ''}
                </ul>
            </div>
        )
    }
    return (
        <div className="stiky">
            <nav>
                <div className="nav-wrapper pl-10 pr-10 white">
                    {/* <a href="#!" className="brand-logo">Logo</a> */}
                    <LinkRef clas="logo brand-logo" to="/">
                    <img className="w-100 centrar h-100" src={logo} alt="Logo" />
                    </LinkRef>
                    {/*<a href="#!" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons dark-text">menu</i></a>*/}
                    {listNav()}
                    <ul className="right hide-on-med-and-up">
                        {TypeSesion === "Invitado" ?
                            <li className="sm"><a href={"https://accounts.xpertme.com/?"+appid} className="text-primary">Iniciar sesión</a></li>
                            : ''}
                    </ul>
                </div>
            </nav>

        </div>
    )
}