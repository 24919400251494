import React, { useCallback, useEffect, useState } from "react";
import wrapper from "../../hooks/wrapper";
import CardComponent from "../Others/CardComponet";
import M from "materialize-css";

export default function OurPlans(params) {
    const [DataPlans, setDataPlans] = useState({})
    const getPlan = useCallback(async () => {
        let plan = await wrapper.getDataU('plans')
        setDataPlans(plan?.data?.data)
        // console.log(plan?.data?.data);
    }, [])
    useEffect(() => {
        getPlan()
    }, [getPlan])
    return (


        <div className="bg-plan p-40">
             <div className="container">
             <h2 className="dark-text uppercase-letters">Xpertcad Industria 4.0</h2>
             
                 <div className="row">
                     {DataPlans?.plans?.map((e, i) => {
                         return (
                             <div className="col s12 m12 l6 xl4 " >
                                 <div className="card-panel hoverable">

                                     <h3 className="strong-b black-text center title-p">{e?.PlanName}</h3>
                                     <p className="center text-p">{e?.PlanDescriptionShort}</p>
                                     <h4 className="center strong-b cash-marge"> ${e?.PlanPricePublic} {e?.PlanPriceCurrency}/MES</h4>
                                     <br></br>

                                     <a className="waves-effect waves-light orange btn w-100" href={'/plans/'+e?.PlanSlug}>Saber Más</a>
                                     <br></br>
                                     <br></br>
                                     <ul className="l-paddin">
                                         <li className="black-text pointer left l-100"><i
                                             className="material-icons-outlined left">done</i>Plazo del plan mensual
                                         </li>
                                         <li className="black-text pointer left l-100"><i
                                             className="material-icons-outlined left">done</i>Tipo de cuenta personal
                                         </li>
                                         <li className="black-text pointer left l-100"><i
                                             className="material-icons-outlined left">done</i>Tipo de acceso online
                                         </li>
                                         <li className="black-text pointer left l-100"><i
                                             className="material-icons-outlined left">done</i>Soporte técnico
                                         </li>
                                     </ul>
                                     <br></br>

                                 </div>
                             </div>
                         )
                     })}
                     {DataPlans?.groups?.map((e, i) => {
                         return (
                             <div className="col s12 m12 l6 xl4 ">
                                 <div className="card-panel hoverable">

                                     <h3 className="strong-b black-text center title-p">{e?.PGroupName}</h3>
                                     <p className="center text-p">{e?.PGroupDescription}</p>
                                     <h4 className="center strong-b cash-marge"> ${e?.PricePublic} {e?.PriceCurrency}/MES</h4>
                                     <br></br>
                                     <a className="waves-effect waves-light orange btn w-100" href={i === 0?'/plans/grupo/certifications':'/plans/grupo/specializations'}>Saber Más</a>
                                     <br></br>
                                     <br></br>

                                     {e.PGroupID  == 9 ?
                                         <>
                                             <ul className="l-paddin">
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Plazo del plan trimestral
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Tipo de cuenta personal
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Tipo de acceso online
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Soporte técnico
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Ejercicios enfocados en
                                                     certificación
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Guia de aprendizaje
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Tips para certificación
                                                 </li>
                                             </ul>
                                             <br /><small>* El precio es por cada preparación para certificación</small>
                                         </>:
                                         <>
                                            <ul className="l-paddin">
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Plazo del plan
                                                     semestral
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Tipo de cuenta
                                                     personal
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Tipo de acceso online
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Soporte técnico
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Ejercicios enfocados
                                                     en la industria
                                                 </li>
                                                 <li className="black-text pointer left l-100"><i
                                                     className="material-icons-outlined left">done</i>Guia de aprendizaje
                                                 </li>
                                             </ul>
                                            <br /><small>* El precio es por cada especialización</small>
                                        </>
                                            }




                                 </div>
                             </div>
                         )
                     })}
                   
                 </div>
                 <p className="left-align n-p text-p"><b>Nota:</b> Nuestros precios incluyen Impuestos</p>
              </div>
        </div>
        
        
    )
}